import React from "react";
import { useTranslation } from "react-i18next";

import { useSearchDetailsStore } from "../../store/useSearchDetailsStore";
import { usePersonalInfoStore } from "../../store/usePersonalInfoStore";
import { DATA_COUNTRY } from "../../utils/data";
import { useProfileStore } from "../../store/useProfileStore";
import { useVerifyStore } from "../../store/useVerifyStore";
import { useContactJobStore } from "../../store/useContactJobStore";
import { LocationContent } from "../../components/common/LocationContent";
import { useControlsStore } from "../../store/useControlsStore";
import { NavLink } from "react-router-dom";

export const Account = ({
  handleEmailCheck,
  handleCodeSelect,
  handleMobileVerify,
  handleOtpVerify,
  handleLatLong,
  handleProfileData,
  handleUpdateProfile,
  isCode,
  setIsMembership,
  setIsWarning,
  setIsDisableAccount,
  themeDark,
}) => {
  const { t } = useTranslation();

  const { setShowModal, setIsEditCard, membership } = useControlsStore();
  const { contactCode, setContactCode } = useContactJobStore();
  const { edit, setEdit } = useProfileStore();
  const { profile, setProfile } = useSearchDetailsStore();
  const { personalInfo, setPersonalInfo } = usePersonalInfoStore();
  const { phoneVerify, setPhoneVerify, emailVerify, setEmailVerify } =
    useVerifyStore();

  return (
    <div className={`Account ${themeDark ? "daskshow" : "mobile"}`}>
      <h2>{t("text-account")}</h2>
      <div
        className={
          personalInfo.personal == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setPersonalInfo({
              ...personalInfo,
              personal: personalInfo.personal == "" ? "active" : "",
            })
          }
        >
          {t("Personal Info")}
        </h3>
        {personalInfo.personal == "active" ? (
          <div className="withoutedit">
            {edit == "" ? (
              <button onClick={(e) => setEdit("edit")}>
                <img src={window.location.origin + "/images/edit.svg"} alt="" />
              </button>
            ) : (
              ""
            )}
            {edit == "" ? (
              <div className="detail">
                <ul>
                  <li className="disabledata">
                    <span className="main">{t("First Name")}</span>
                    <span className="data">{profile.first_name}</span>
                  </li>
                  <li>
                    <span className="main">{t("Last Name")}</span>
                    <span className="data">{profile.last_name}</span>
                  </li>
                  <li className="disabledata">
                    <span className="main">{t("Date of birth")}</span>
                    <span className="data">{profile.dob}</span>
                  </li>
                  <li>
                    <span className="main">{t("Mobile")}</span>
                    <span className="data">
                      {(contactCode.code == ""
                        ? profile.countrycode
                        : contactCode.code) + profile.phone}
                    </span>
                  </li>
                  <li>
                    <span className="main">{t("Email")} </span>
                    <span className="data">{profile.email}</span>
                  </li>
                  <li>
                    <span className="main">{t("Address")}</span>
                    <span className="data">{profile.address}</span>
                  </li>
                  <li>
                    <span className="main">{t("text-city")}</span>
                    <span className="data">{profile.city}</span>
                  </li>
                  <li>
                    <span className="main">{t("zip-code")}</span>
                    <span className="data">{profile.zip}</span>
                  </li>
                  <li>
                    <span className="main">{t("Country")}</span>
                    <span className="data">{profile.country}</span>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="panel-body">
                <div className="row">
                  <div className="form-group col-md-6">
                    <div className="field-box">
                      <label className="label" for="firstName">
                        {t("First Name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        disabled
                        value={profile.first_name}
                      />
                    </div>
                    <div className="field-box">
                      <label className="label" for="lastName">
                        {t("Last Name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={profile.last_name}
                        onChange={(e) =>
                          setProfile({
                            ...profile,
                            last_name: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="field-box">
                      <label className="label" for="email">
                        {t("email-address")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        data-required
                        defaultValue={profile.email}
                        onChange={(e) =>
                          setEmailVerify({
                            ...emailVerify,
                            email: e.target.value,
                          })
                        }
                        onBlur={(e) => handleEmailCheck()}
                      />
                      <span>
                        {emailVerify.alemail
                          ? emailVerify.alemail
                          : profile.email_verified_at == null
                          ? "Please verify your enail"
                          : ""}
                      </span>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <div className="form_group number field-box">
                      <label className="label" for="mobile">
                        {t("Mobile Phone")}
                      </label>
                      <input
                        type="number"
                        placeholder={t("text-type")}
                        name="phone"
                        onChange={(e) => {
                          setPhoneVerify({
                            ...phoneVerify,
                            phone:
                              e.target.value.length <= 10
                                ? e.target.value
                                : profile.phone,
                          });
                        }}
                        defaultValue={profile.phone}
                      />
                      <div
                        className="country_flag"
                        onClick={(e) => handleCodeSelect()}
                      >
                        <img
                          src={
                            contactCode.flag
                              ? contactCode.flag
                              : DATA_COUNTRY.data.filter((e) => {
                                  if (
                                    e.dial_code ==
                                    "+" + profile.countrycode
                                  ) {
                                    return e.flag;
                                  }
                                })[0].flag
                          }
                          alt="county flag"
                        />
                        {contactCode.code
                          ? contactCode.code
                          : profile.countrycode}
                      </div>
                      <ul style={isCode ? { display: "none" } : {}}>
                        {DATA_COUNTRY.data.map((e) => {
                          return (
                            <li
                              onClick={(a) => {
                                handleCodeSelect();
                                setContactCode({
                                  flag: e.flag,
                                  code: e.dial_code,
                                });
                              }}
                            >
                              <img src={e.flag} />
                              {e.country + " " + " " + e.dial_code}
                            </li>
                          );
                        })}
                      </ul>
                      {phoneVerify.phone != "" &&
                      profile.phone != phoneVerify.phone ? (
                        <button onClick={(e) => handleMobileVerify()}>
                          {t("get-code")}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {phoneVerify.phone != "" &&
                    profile.phone != phoneVerify.phone ? (
                      <div className="field-box">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="OTP"
                          onChange={(e) => {
                            setPhoneVerify({
                              ...phoneVerify,
                              otp: e.target.value,
                            });
                          }}
                        />
                        <button onClick={(e) => handleOtpVerify()}>
                          {t("text-verify")}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="field-box">
                      <label className="label" for="address1">
                        {t("Address")}
                      </label>
                      <LocationContent
                        let={handleLatLong}
                        typedefault={profile.address}
                      />
                    </div>
                    <div className="field-box">
                      <div className="row coln">
                        <div className="col-md-6">
                          <label className="label" for="city">
                            {t("text-city")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            data-required
                            value={profile.city}
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                city: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="col-md-6">
                          <label className="label" for="zipCode">
                            {t("ZIP Code")}
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="zipCode"
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                zip: e.target.value,
                              })
                            }
                            value={profile.zip}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="field-box">
                      <label className="label" for="country">
                        {t("Country")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={profile.country}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="resetpassword">
              <div className="form_group disabledata">
                <label>{t("Username")}</label>
                <input type="text" value={profile.username} disabled />
              </div>
              <div className="form_group ">
                <label>{t("text-password")}</label>
                <input type="password" value="........" />
              </div>
              <button onClick={(e) => setShowModal(true)}>
                {t("Change password")}
              </button>
            </div>
            <div className="panel-body savebutton">
              <div className="button text-center">
                <div className="pull-right">
                  <button
                    className="btn"
                    onClick={(e) => {
                      setContactCode({
                        ...contactCode,
                        code: "",
                      });
                      handleProfileData();
                      setEdit("");
                    }}
                  >
                    {t("text-cancel")}
                  </button>
                </div>
                <div className="pull-right">
                  <button
                    className="btn confirm"
                    onClick={(e) => handleUpdateProfile()}
                  >
                    {t("Save changes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          personalInfo.payment == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setPersonalInfo({
              ...personalInfo,
              payment: personalInfo.payment == "" ? "active" : "",
            })
          }
        >
          {t("Payment and Membership")}
        </h3>
        {personalInfo.payment == "active" ? (
          <div className="cardstype">
            <div className="paymenttype"></div>
            <h4>{t("My Cards")}</h4>
            <div className="savecard">
              <ul>
                <li>
                  <label for="c">
                    
                    <input type="radio" id="c" name="c" />
                    <span>{t("Set as default")}</span>
                  </label>
                  <span>{t("Card name")}</span>
                  <p>{t("Bankname card ***4589")}</p>
                  <span>{t("expires-on")}</span>
                  <p>{t("04/2022")}</p>
                  <button>{t("text-remove")}</button>
                  <button onClick={(e) => setIsEditCard(true)}>
                    {t("Edit")}
                  </button>
                </li>
                <li>
                  <label for="d">
                    
                    <input type="radio" id="d" name="c" />
                    <span>{t("Set as default")}</span>
                  </label>
                  <span>{t("Card name")}</span>
                  <p>{t("Bankname card ****4589")}</p>
                  <span>{t("expires-on")}</span>
                  <p>{t("04/2022")}</p>
                  <button>{t("text-remove")}</button>
                  <button onClick={(e) => setIsEditCard(true)}>Edit</button>
                </li>
                <li>
                  <label for="e">
                    
                    <input type="radio" id="e" name="c" />
                    <span>{t("Set as default")}</span>
                  </label>
                  <span>{t("Card name")}</span>
                  <p>{t("Bankname card ****4589")}</p>
                  <span>{t("expires-on")}</span>
                  <p>{t("04/2022")}</p>
                  <button>{t("text-remove")}</button>
                  <button onClick={(e) => setIsEditCard(true)}>Edit</button>
                </li>
              </ul>
            </div>
            <div className="activeplan">
              <ul>
                <li>
                  <label>
                    {t("Current membership plan")}
                    <span>
                      {membership ? t(membership) : t(" Plan not active")}
                    </span>
                  </label>
                  <button onClick={(e) => setIsMembership(true)}>
                    {t("Cancel plan")}
                  </button>
                  <NavLink to={""}>
                    
                    <button>{t("Change plan")}</button>
                  </NavLink>
                </li>
                <li>
                  <label>{t("Subscription")}</label>
                  <p>
                    {t("Auto renew subscription")}
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          personalInfo.delete == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setPersonalInfo({
              ...personalInfo,
              delete: personalInfo.delete == "" ? "active" : "",
            })
          }
        >
          {t("Deactivate/ Delete account")}
        </h3>
        {personalInfo.delete == "active" ? (
          <div className="activeplan deletedeactivate">
            <ul>
              <li>
                <label>
                  {t("Current account status plan")}
                  <span>
                    {profile.account_status == "ENABLE"
                      ? t("text-active")
                      : t("Inactive")}
                  </span>
                </label>
                <button onClick={(e) => setIsWarning(true)}>
                  {t("Delete account")}
                </button>
                <button onClick={(e) => setIsDisableAccount(true)}>
                  {t("Disable account")}
                </button>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
      <br />
      <br />
    </div>
  );
};
