import React, { useState, useEffect } from "react";
import { fetchMessageCount } from "../../../api";

export function MessageCount() {
  const [messageData, setMessageData] = useState({});

  useEffect(() => {
    const fetchAndUpdateMessageCount = async () => {
      try {
        const data = await fetchMessageCount();
        setMessageData(data);
      } catch (error) {
        console.error("Error fetching message count:", error);
      }
    };

    fetchAndUpdateMessageCount();
    const intervalId = setInterval(fetchAndUpdateMessageCount, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return <>{messageData.msg_count}</>;
}
