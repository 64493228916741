import React from "react";

export const FormInput = ({
  label,
  type,
  name,
  placeholder,
  errorField,
  onChange,
  onBlur,
}) => {
  return (
    <div className="form_group">
      <label>{label}</label>
      <div
        className={
          (name === "email" &&
            errorField["email"] === "The email has already been taken.") ||
          (name === "username" &&
            errorField["username"] === "The username has already been taken.")
            ? "bordererror"
            : ""
        }
      >
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          className={
            errorField[name] === "" ||
            (name === "email" &&
              errorField["email"] === "The email has already been taken.") ||
            (name === "username" &&
              errorField["username"] === "The username has already been taken.")
              ? ""
              : "bordererror"
          }
        />
      </div>
      <span className="errorfield">{errorField[name]}</span>
    </div>
  );
};
