import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../urls";
import Modal from "react-bootstrap/Modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

import { NotificationCount } from "../../components/common/NotificationCount";
import { SquareServiceIcon } from "../../assets/image/SquareServiceIcon";
import { RoundArrowUp } from "../../assets/image/RoundArrowUp";
import { AddIcon } from "../../assets/image/AddIcon";
import { DumpIcon } from "../../assets/image/DumpIcon";
import { NotificationTabParents } from "../../components/common/NotificationTabParents";
import { NotificationTabProvider } from "../../components/common/NotificationTabProvider";
import { ParentsNotificationTabs } from "../../components/common/ParentsNotificationTabs";
import { ProviderNotificationTabs } from "../../components/common/ProviderNotificationTabs";
import { ViewEdit } from "../../components/common/ViewEdit";
import { ViewEditProvider } from "../../components/common/ViewEditProvider";
import { FavoriteProvider } from "../../components/common/FavoriteProvider";
import { JobHistoryParents } from "../../components/Job/JobHistoryParents";
import { JobHistory } from "../../components/Job/JobHistory";
import { MessageTab } from "../../components/Message/MessageTab";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { SearchFilter } from "./SearchFilter";
import { VALID_PASSWORD } from "../../utils/variables";
import { useSearchDetailsStore } from "../../store/useSearchDetailsStore";
import { useCategoryStore } from "../../store/useCategoryStore";
import { useAdditionalDetailsStore } from "../../store/useAdditionalDetailsStore";
import { CandidateList } from "./CandidateList";
import { Account } from "./Account";
import { usePersonalInfoStore } from "../../store/usePersonalInfoStore";
import { useProfileStore } from "../../store/useProfileStore";
import { useVerifyStore } from "../../store/useVerifyStore";
import { useContactJobStore } from "../../store/useContactJobStore";
import { useControlsStore } from "../../store/useControlsStore";
import { SearchLeftSection } from "./SearchLeftSection";
import { SearchRightSection } from "./SearchRightSection";
import { PerfectJob } from "./PerfectJob";

import { SearchProfileDetail } from "./SearchProfileDetail";
import { ChangePasswordModal } from "../../components/Modals/ChangePasswordModal";
import { NewCardModal } from "../../components/Modals/NewCardModal";
import { MembershipModal } from "../../components/Modals/MembershipModal";
import { DisableAccountModal } from "../../components/Modals/DisableAccountModal";
import { WarningModal } from "../../components/Modals/WarningModal";
import { SurveyModal } from "../../components/Modals/SurveyModal";
import { AutoDeleteModal } from "../../components/Modals/AutoDeleteModal";
import { SignoutModal } from "../../components/Modals/SignoutModal";
import { EditCardModal } from "../../components/Modals/EditCardModal";
import { ProviderModal } from "../../components/Modals/ProviderModal";
import { SuccessModal } from "../../components/Modals/SuccessModal";

export function SearchParentsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sliderRef = useRef();

  const { contactCode } = useContactJobStore();
  const { setEdit } = useProfileStore();
  const { phoneVerify, emailVerify, setEmailVerify } = useVerifyStore();

  const { search, setSearch, usd, profile, setProfile } =
    useSearchDetailsStore();

  const { childrenAge, setChildrenAge, setIsResult } =
    useAdditionalDetailsStore();

  const {
    selectCategory,
    jobCategory,
    isCheck,
    setIsCheck,
    category,
    setCategory,
  } = useCategoryStore();

  const { password, setPassword, errorPassword, setErrorPassword } =
    usePersonalInfoStore();

  const { showModal, setShowModal, setMembershipData } = useControlsStore();

  const [providerData, setProviderData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [showProviderModal, setShowProviderModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isPhotoUploadModal, setIsPhotoUploadModal] = useState(false);
  const [showGeneralProviderModal, setShowGeneralProviderModal] =
    useState(false);
  const [isShowSuccessModal, setIsShowSuccess] = useState(false);

  const [filter, setfilter] = useState(
    JSON.parse(localStorage.getItem("filter")) != null
      ? JSON.parse(localStorage.getItem("filter"))
      : {}
  );

  const [hireRateJob, setHireRateJob] = useState([]);
  const [count, setCount] = useState(true);
  const [isElement, setIsElement] = useState(true);
  const [isFilterCheck, setIsFilterCheck] = useState(true);
  const [showCorrectList, setShowCorrectList] = useState(10);

  const [advanceSearch, setAdvanceSearch] = useState(false);

  const [editCountry, setEditCountry] = useState(false);
  const [dataCountry, setDataCountry] = useState("");

  const [latLong, setLatLong] = useState({
    lat: "",
    lng: "",
  });
  const handleCloseProviderModal = () => {
    setShowProviderModal(false);
  };
  const handleShowProviderModal = () => {
    setShowProviderModal(true);
  };

  const slugdata = useParams();
  let data = slugdata.id;

  const [profileSection, setProfileSection] = useState(
    localStorage.getItem("side") && localStorage.getItem("side") != ""
      ? localStorage.getItem("side")
      : ""
  );
  const [type, setType] = useState(data ? data : "");

  const [image, setImage] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [photo, setPhoto] = useState("");
  const [isNewCardModal, setIsNewCardModal] = useState(false);
  const [isMembership, setIsMembership] = useState(false);
  const [isDisableAccountModal, setIsDisableAccountModal] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);

  const [isAutoDeleteModal, setIsAutoDeleteModal] = useState(false);
  const [isSignoutModal, setIsSignoutModal] = useState(false);
  const [isApologyModal, setIsApologyModal] = useState(false);
  const [isSurvey, setIsSurvey] = useState(false);

  const [surveyData, setSurveyData] = useState({
    resion: "",
    other: "",
    status: "",
  });

  const handleCloseModal = () => setShowModal(false);

  const handleSelectCategory = (data) => {
    let sum = false;
    category.map((e, index) => {
      if (e.name == data) {
        sum = true;
        category.splice(index, 1);
      }
    });
    if (sum == false) {
      category.push({ name: data });
    }
    setTimeout(() => {
      setCategory([...category]);
    }, 500);
  };

  const handleSelectYears = (data) => {
    let sum = false;
    childrenAge.map((e, index) => {
      if (e.name == data) {
        sum = true;
        childrenAge.splice(index, 1);
      }
    });
    if (sum == false) {
      childrenAge.push({ name: data });
    }
    setTimeout(() => {
      setChildrenAge([...childrenAge]);
    }, 500);
    console.log(childrenAge);
  };

  const handleElementById = (e) => {
    console.log(e);
    setAdvanceSearch(true);
    if (isCheck) {
      document.getElementById(e).style.display = "block";
      setIsCheck(false);
    } else {
      document.getElementById(e).style.display = "none";
      setIsCheck(true);
    }
  };

  const handleElementDisplay = (e, x) => {
    if (isCheck) {
      document.getElementById(e).style.display = "block";
      document.getElementById(x).style.display = "block";
      setIsCheck(false);
    } else {
      setIsCheck(true);
      document.getElementById(e).style.display = "none";
      document.getElementById(x).style.display = "none";
    }
  };
  useEffect(() => {
    if (count) {
      handleProviderData();
      handleProfileData();
      handleMembershipData();
      setCount(false);
    }
    console.log(filter);
    localStorage.setItem("side", profileSection);
  }, [
    profile,
    providerData,
    category,
    errorPassword,
    profileSection,
    password,
    isWarningModal,
    photo,
  ]);

  useEffect(() => {
    setType(data ? data : "");
  }, [data, slugdata, type]);

  useEffect(() => {
    localStorage.setItem(
      "back",
      type + (slugdata.name ? "/" + slugdata.name : "")
    );
  }, [data, slugdata, type, providerData, category, errorPassword]);

  const handleProfileData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setProfile(result.data);
        setAdvanceSearch(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleProviderData = (data) => {
    setProviderData([]);
    setHireRateJob([]);
    setIsResult("");
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      api +
        "/api/" +
        (localStorage.getItem("token") !== null
          ? "v1/favjobslisting"
          : "jobslisting") +
        (localStorage.getItem("searchdataprovider")
          ? JSON.parse(localStorage.getItem("searchdataprovider"))
          : "?keyword=" +
            search.keyword +
            "&distance=" +
            (data ? data : search.distance) +
            "&lat=" +
            latLong.lat +
            "&lng=" +
            latLong.lng +
            "&city=" +
            search.city +
            "&zip=" +
            search.zip +
            "&tutorintrestedin[]=" +
            search.tutorintrestedin +
            "&tutorintrestedin[]=" +
            search.tutorintrestedin1 +
            "&tutorintrestedin[]=" +
            search.tutorintrestedin2 +
            (jobCategory.data1 !== ""
              ? "&jobcategory[]=" + jobCategory.data1
              : "&jobcategory[]=") +
            (jobCategory.data2 !== ""
              ? "&jobcategory[]=" + jobCategory.data2
              : "&jobcategory[]=") +
            (jobCategory.data3 !== ""
              ? "&jobcategory[]=" + jobCategory.data3
              : "&jobcategory[]=") +
            (jobCategory.data4 !== ""
              ? "&jobcategory[]=" + jobCategory.data4
              : "&jobcategory[]=") +
            "&tutorworkwithnochild" +
            (search?.children ? search?.children : "") +
            "&rates=" +
            (usd.max > 5 ? usd.min + "-" + usd.max : "") +
            "&workingabroad=" +
            search.workingabroad +
            "&tutorintrestedinschool=" +
            search.tutorintrestedinschool +
            (selectCategory.data1 !== ""
              ? "&jobcategory[]=" + selectCategory.data1
              : "&jobcategory[]=") +
            (selectCategory.data2 !== ""
              ? "&jobcategory[]=" + selectCategory.data2
              : "&jobcategory[]=") +
            (selectCategory.data3 !== ""
              ? "&jobcategory[]=" + selectCategory.data3
              : "&jobcategory[]=") +
            (selectCategory.data4 !== ""
              ? "&jobcategory[]=" + selectCategory.data4
              : "&jobcategory[]=") +
            "&tutorprefchildage=" +
            search.tutorprefchildage +
            "&carorlicence=" +
            search.carorlicence +
            "&withinweek=" +
            search.withinweek +
            "&withinmonth=" +
            search.withinmonth +
            "&jobposted=" +
            search.jobposted),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        let datafil = result.data.filter(
          (data) => data.nanyperhrrate != null || data.tutorperhrrate != null
        );
        localStorage.setItem(
          "searchdataprovider",
          JSON.stringify(
            "?keyword=" +
              search.keyword +
              "&distance=" +
              (data ? data : search.distance) +
              "&lat=" +
              latLong.lat +
              "&lng=" +
              latLong.lng +
              "&city=" +
              search.city +
              "&zip=" +
              search.zip +
              "&tutorintrestedin[]=" +
              search.tutorintrestedin +
              "&tutorintrestedin[]=" +
              search.tutorintrestedin1 +
              "&tutorintrestedin[]=" +
              search.tutorintrestedin2 +
              (jobCategory.data1 !== ""
                ? "&jobcategory[]=" + jobCategory.data1
                : "&jobcategory[]=") +
              (jobCategory.data2 !== ""
                ? "&jobcategory[]=" + jobCategory.data2
                : "&jobcategory[]=") +
              (jobCategory.data3 !== ""
                ? "&jobcategory[]=" + jobCategory.data3
                : "&jobcategory[]=") +
              (jobCategory.data4 !== ""
                ? "&jobcategory[]=" + jobCategory.data4
                : "&jobcategory[]=") +
              "&tutorworkwithnochild" +
              (search?.children ? search?.children : "") +
              "&rates=" +
              (usd.max > 5 ? usd.min + "-" + usd.max : "") +
              "&workingabroad=" +
              search.workingabroad +
              "&tutorintrestedinschool=" +
              search.tutorintrestedinschool +
              (selectCategory.data1 !== ""
                ? "&jobcategory[]=" + selectCategory.data1
                : "&jobcategory[]=") +
              (selectCategory.data2 !== ""
                ? "&jobcategory[]=" + selectCategory.data2
                : "&jobcategory[]=") +
              (selectCategory.data3 !== ""
                ? "&jobcategory[]=" + selectCategory.data3
                : "&jobcategory[]=") +
              (selectCategory.data4 !== ""
                ? "&jobcategory[]=" + selectCategory.data4
                : "&jobcategory[]=") +
              "&tutorprefchildage=" +
              search.tutorprefchildage +
              "&carorlicence=" +
              search.carorlicence +
              "&withinweek=" +
              search.withinweek +
              "&withinmonth=" +
              search.withinmonth +
              "&jobposted=" +
              search.jobposted
          )
        );
        setProviderData(datafil);
        setInitialData(datafil);
        setTimeout(() => {
          setIsResult("ubb");
        }, 500);
      })
      .catch((error) => console.log("error", error));
    fetch(
      api +
        "/api/" +
        (localStorage.getItem("token") == null
          ? "highratedjobs"
          : "v1/authhighratedjobs"),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        let x = [];
        result.data.map((data) => {
          if (data != null && data.status == 0) {
            x.push(data);
            setHireRateJob(x);
          }
        });
      })
      .catch((error) => console.log("error", error));
  };

  const handleParseRate = (rate) => {
    const values = rate.substr(rate.lastIndexOf("\\") + 1).split("-");
    return {
      min: parseInt(values[0]),
      max: parseInt(values[1]),
    };
  };

  const handleSortedData = (e) => {
    setProviderData([]);

    if (e === "Reviews") {
      const sortedData = [...initialData].sort(
        (a, b) => b.reviewcount - a.reviewcount
      );
      setProviderData(sortedData);
      console.log(sortedData);
    } else if (e === "Low to high") {
      const sortedData = [...initialData].sort((a, b) => {
        const rangeA = handleParseRate(a.nanyperhrrate);
        const rangeB = handleParseRate(b.nanyperhrrate);

        return rangeA.min - rangeB.min || rangeA.max - rangeB.max;
      });
      setProviderData(sortedData);
    } else if (e === "High to low") {
      const sortedData = [...initialData].sort((a, b) => {
        const rangeA = handleParseRate(a.nanyperhrrate);
        const rangeB = handleParseRate(b.nanyperhrrate);
        return rangeB.min - rangeA.min || rangeB.max - rangeA.max;
      });
      setProviderData(sortedData);
    } else if (e === "Distance") {
      setSearch({ ...search, distance: 10 });
      setTimeout(() => {
        localStorage.removeItem("searchdataprovider");
        handleProviderData(10);
      }, 1000);
    } else if (e === "Relevance") {
      setProviderData(initialData);
    }
  };

  const toggleMoreContent = (e, x, z) => {
    document.getElementById(e).style.display = "none";
    document.getElementById(x).style.display = "block";
    if (isElement) {
      document.getElementById(z).style.display = "block";
      setIsElement(false);
    } else {
      setIsElement(true);
      document.getElementById(z).style.display = "none";
    }
  };

  const hadnleFilterElement = () => {
    if (isFilterCheck) {
      document.getElementById("filteron").style.display = "block";
      setIsFilterCheck(false);
    } else {
      document.getElementById("filteron").style.display = "none";
      setIsFilterCheck(true);
    }
  };
  const handleLatLong = (latlong2, e, addresss) => {
    e.then(function (result) {
      setLatLong(result);
    });
    let x = "";
    let y = "";
    for (let i = 0; i < latlong2.address_components.length; i++) {
      for (let j = 0; j < latlong2.address_components[i].types.length; j++) {
        if (
          latlong2.address_components[i].types[j] ==
          "administrative_area_level_1"
        ) {
          x = latlong2.address_components[i].long_name;
        }
      }
    }
    for (let i = 0; i < latlong2.address_components.length; i++) {
      for (let j = 0; j < latlong2.address_components[i].types.length; j++) {
        if (
          latlong2.address_components[i].types[j] == "postal_code" ||
          latlong2.address_components[i].types[j] == "street_number"
        ) {
          y = latlong2.address_components[i].long_name;
        }
      }
    }
    latlong2.address_components.map((e) => {
      console.log(addresss);
      e.types.map((a) => {
        if (a == "country") {
          setProfile({
            ...profile,
            country: e.long_name,
            address: addresss,
            zip: y,
            city: x,
          });
        }
      });
    });
    if (e != "getLatLng") {
      e.then(function (result) {
        setLatLong(result);
        setSearch({ ...search, zip: parseInt(addresss) });
        console.log(result);
      });
    } else {
      setSearch({ ...search, zip: "" });
    }
  };

  const [isCode, setIsCode] = useState(true);

  const handleCodeSelect = () => {
    if (isCode) {
      setIsCode(false);
    } else {
      setIsCode(true);
    }
  };
  const handleUpdateProfile = () => {
    if (emailVerify.alemail == "") {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        address: profile.address,
        last_name: profile.last_name,
        city: profile.city,
        zip: profile.zip,
        country: profile.country,
        lat: latLong.lat ? latLong.lat : profile.lat,
        lng: latLong.lng ? latLong.lng : profile.lng,
        countrycode: contactCode.code ? contactCode.code : profile.countrycode,
        phone: profile.phone,
        email: emailVerify.email ? emailVerify.email : profile.email,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(api + "/api/v1/updateprofile", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            setTimeout(() => {
              localStorage.setItem(
                "name",
                profile.first_name + " " + profile.last_name
              );
              setProfile(result.data.user);
              setEdit("");
            }, 1000);
          }
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    } else {
    }
  };

  const handleChangePassword = () => {
    if (password.n_password && password.oldpassword && password.c_password) {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        current_password: password.oldpassword,
        new_password: password.n_password,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(api + "/api/v1/passwordchange", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.type == "Failed") {
            setErrorPassword({
              oldpassword: "password did not match",
              c_password: "",
              n_password: "",
            });
          } else {
            setIsShowSuccess(true);
            setShowModal(false);
            setErrorPassword({
              oldpassword: "",
              c_password: "",
              n_password: "",
            });
            localStorage.removeItem("id");
            localStorage.removeItem("token");
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      setErrorPassword({
        oldpassword: password.oldpassword ? "" : "Required",
        c_password: password.c_password ? "" : "Required",
        n_password: password.n_password ? "" : "Required",
      });
    }
  };

  const handleProfileUpdate = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("file_path", photo);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateparents", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setIsPhotoUploadModal(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  function handleChangeVerify(value) {
    console.log("Captcha value:", value);
    setIsVerified(true);
  }

  const validateLoginFields = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "oldpassword":
        errorPassword.oldpassword = value ? "" : "required";
        break;
      case "c_password":
        errorPassword.c_password =
          value != password.n_password ? "Password did not match" : "";
        break;
      case "n_password":
        errorPassword.n_password = VALID_PASSWORD.test(value)
          ? ""
          : "Password did not match";
        break;
      default:
        break;
    }
    setPassword({ ...password, [name]: value });
    setErrorPassword(errorPassword);
  };

  const handleDisableAccount = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      account_status: "DISABLE",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/disableaccount", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsDisableAccountModal(false);
        setProfile({ ...profile, account_status: "DISABLE" });
        setIsSurvey(true);
        setSurveyData({ ...surveyData, status: "Disable" });
      })
      .catch((error) => console.log("error", error));
  };

  const handleDeleteAccount = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/deleteaccount", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSurveyData({ ...surveyData, status: "Delete" });
        setIsSurvey(true);
      })
      .catch((error) => console.log("error", error));
  };

  const handleSaveSurvey = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      username: profile.username,
      role: profile.user_type,
      reason_type: surveyData.resion,
      other_reason: surveyData.other,
      status: surveyData.status,
      user_id: profile.id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/usersurvey", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        localStorage.removeItem("id");
        localStorage.removeItem("token");
        if (surveyData.status == "Delete") {
          setIsApologyModal(true);
        } else {
          window.location.replace("/");
        }
        setIsSurvey(false);
      })
      .catch((error) => console.log("error", error));
  };

  const handleMobileVerify = () => {
    if (phoneVerify.phone.length > 7) {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonenoforverify:
          contactCode.code != ""
            ? contactCode.code + phoneVerify.phone
            : "+" + profile.countrycode + phoneVerify.phone,
        phone: phoneVerify.phone,
        countrycode:
          contactCode.code != "" ? contactCode.code : "+" + profile.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/sendotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
    }
  };

  const handleOtpVerify = () => {
    if (phoneVerify.otp.length > 3) {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonetoken: phoneVerify.otp,
        phone: phoneVerify.phone,
        countrycode:
          contactCode.code != "" ? contactCode.code : "+" + profile.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/verifyotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          handleProfileData();
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    } else {
      setErrorPassword({
        ...errorPassword,
        otperror: "error",
      });
    }
  };

  const handleEmailCheck = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: emailVerify.email,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/checkemail", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.isExist == 1) {
          setEmailVerify({ alemail: result.message });
        } else {
          setEmailVerify({ alemail: "" });
        }
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const handleEmailSurvey = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      id: profile.id,
    });
    const requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/sendsurveymail", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        localStorage.removeItem("id");
        localStorage.removeItem("token");
        setIsSurvey(false);
        if (surveyData.status == "Delete") {
          setIsApologyModal(true);
        } else {
          window.location.replace("/");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handleMembershipData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/getmembershipplan", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setMembershipData(result.data.name);
      })
      .catch((error) => console.log("error", error));
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setPhoto(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setLatLong({
        lat: latitude,
        lng: longitude,
      });
    }
    function error() {
      console.log("Unable to retrieve your location");
    }
  }, [search]);

  return (
    <>
      <Header fil={hadnleFilterElement} />
      <div className="container-fluid">
        <SearchFilter
          handleElementById={handleElementById}
          handleProviderData={handleProviderData}
          handleElementDisplay={handleElementDisplay}
          handleSelectYears={handleSelectYears}
          handleSelectCategory={handleSelectCategory}
          advanceSearch={advanceSearch}
          setType={setType}
        />
        {type == "Profile" ? (
          ""
        ) : (
          <>
            {profile.about == "" || profile.about == null ? (
              ""
            ) : (
              <>
                <SearchProfileDetail />
              </>
            )}
          </>
        )}

        <div
          className="filter_header2"
          id="filteron"
          style={
            !localStorage.getItem("token") || !localStorage.getItem("id")
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <div
            className="filter_box"
            onKeyPress={(e) => {
              if (
                e.key == "Enter" &&
                localStorage.getItem("token") &&
                localStorage.getItem("id")
              ) {
                localStorage.removeItem("searchdataprovider");
                handleProviderData();
              }
            }}
          >
            <div className="container">
              <div className="filter_search">
                <PerfectJob
                  handleElementDisplay={handleElementDisplay}
                  handleProfileData={handleProfileData}
                  handleShowProviderModal={handleShowProviderModal}
                  setType={setType}
                  handleSelecetCategory={handleSelectCategory}
                />
              </div>
            </div>
          </div>
        </div>
        {showProviderModal && (
          <ProviderModal
            showProviderModal={showProviderModal}
            handleCloseProviderModal={handleCloseProviderModal}
            handleProviderData={handleProviderData}
            handleElementById={handleElementById}
            handleSelectCategory={handleSelectCategory}
            handleSelectYears={handleSelectYears}
            setType={setType}
          />
        )}

        {type == "" && (
          <>
            <div className="mobile_looking_for_candidate">
              <h2>
                {!localStorage.getItem("token") && !localStorage.getItem("id")
                  ? t("theseFamilier")
                  : t("These are job offers in your area")}
              </h2>
            </div>
            <div className="mobile_looking_for_candidate_boxs">
              {providerData.map((data, index) => {
                if (index < showCorrectList) {
                  return (
                    <CandidateList
                      data={data}
                      index={index}
                      key={index}
                      toggleMoreContent={toggleMoreContent}
                    />
                  );
                }
              })}
            </div>
          </>
        )}

        {type == "Account" && (
          <Account
            handleEmailCheck={handleEmailCheck}
            handleCodeSelect={handleCodeSelect}
            handleMobileVerify={handleMobileVerify}
            handleOtpVerify={handleOtpVerify}
            handleLatLong={handleLatLong}
            handleProfileData={handleProfileData}
            handleUpdateProfile={handleUpdateProfile}
            setIsWarning={setIsWarningModal}
            setIsDisableAccount={setIsDisableAccountModal}
            isCode={isCode}
            setIsMembership={setIsMembership}
          />
        )}
        {type == "Profile" ? (
          <>
            {localStorage.getItem("user_type") == "parents" ? (
              <div className="mobile profile">
                <ViewEdit />
              </div>
            ) : (
              <div className="mobile profile">
                <ViewEditProvider />
              </div>
            )}
          </>
        ) : (
          ""
        )}
        <div className="container">
          <div
            className="body_section searchbody"
            style={
              type != ""
                ? {
                    minHeight: "auto",
                    marginBottom: "100px",
                    float: "left",
                    width: "100%",
                  }
                : {}
            }
          >
            <SearchLeftSection
              setIsPhotoUploadModal={setIsPhotoUploadModal}
              setType={setType}
              setProfileSection={setProfileSection}
              setIsSignout={setIsSignoutModal}
              type={type}
              profileSection={profileSection}
            />
            {type == "" && (
              <SearchRightSection
                sliderRef={sliderRef}
                providerData={providerData}
                showCorrectList={showCorrectList}
                handleSortedData={handleSortedData}
                setShowCorrectList={setShowCorrectList}
                hireRateJob={hireRateJob}
                setIsElement={setIsElement}
                toggleMoreContent={toggleMoreContent}
              />
            )}

            {type == "Account" && (
              <Account
                themeDark={true}
                handleEmailCheck={handleEmailCheck}
                handleCodeSelect={handleCodeSelect}
                handleMobileVerify={handleMobileVerify}
                handleOtpVerify={handleOtpVerify}
                handleLatLong={handleLatLong}
                handleProfileData={handleProfileData}
                handleUpdateProfile={handleUpdateProfile}
                setIsWarning={setIsWarningModal}
                setIsDisableAccount={setIsDisableAccountModal}
                isCode={isCode}
                setIsMembership={setIsMembership}
              />
            )}
            {type == "Profile" ? (
              <>
                {localStorage.getItem("user_type") == "parents" ? (
                  <ViewEdit />
                ) : (
                  <ViewEditProvider />
                )}
              </>
            ) : (
              ""
            )}
            {localStorage.getItem("user_type") == "parents" ? (
              <ParentsNotificationTabs
                subtab={type}
                profilesection={profileSection}
              />
            ) : (
              <ProviderNotificationTabs
                subtab={type}
                profilesection={profileSection}
              />
            )}
          </div>
        </div>
        <div className="arrowtop">
          <RoundArrowUp />
        </div>
        <div className="mobilemenubottom">
          <div
            className="side_drop_collapse_box_content"
            onClick={(e) => {
              localStorage.removeItem("side");
              setProfileSection("");
            }}
            style={
              profileSection == "notifications"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <span
              onClick={(e) => {
                localStorage.removeItem("side");
                setProfileSection("");
              }}
            ></span>
            {localStorage.getItem("user_type") == "parents" ? (
              <NotificationTabParents
                setsubtab={setType}
                subtab={type}
                profilesection={profileSection}
              />
            ) : (
              <NotificationTabProvider
                setsubtab={setType}
                subtab={type}
                profilesection={profileSection}
              />
            )}
          </div>
          <div
            className="side_drop_collapse_box_content Document_main "
            onClick={(e) => {
              localStorage.removeItem("side");
              setProfileSection("");
            }}
            style={
              profileSection == "fav"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <span
              onClick={(e) => {
                localStorage.removeItem("side");
                setProfileSection("");
              }}
            ></span>
            <ul>
              <li
                onClick={(e) => {
                  navigate(
                    localStorage.getItem("user_type") == "parents"
                      ? "/search-parents/who-i-visited"
                      : "/search-providers/who-i-visited"
                  );
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setType("who-i-visited");
                }}
                className={type == "who-i-visited" ? "active " : ""}
              >
                {t("Who I Visited")}
              </li>
              <li
                onClick={(e) => {
                  navigate(
                    localStorage.getItem("user_type") == "parents"
                      ? "/search-parents/who-visited-me"
                      : "/search-providers/who-visited-me"
                  );
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setType("who-visited-me");
                }}
                className={
                  type == "who-visited-me" ? "active upgrade" : "upgrade"
                }
              >
                {t("Who Visited Me")}
                <span>
                  <Link to={""}>{t("upgrade")}</Link>
                </span>
              </li>
            </ul>
          </div>
          <div
            className="side_drop_collapse_box_content"
            style={
              profileSection == "Favorites"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <span
              onClick={(e) => {
                localStorage.removeItem("side");
                setProfileSection("");
              }}
            ></span>
            {localStorage.getItem("user_type") == "parents" ? (
              <FavoriteProvider
                setsubtab={setType}
                subtab={type}
                setprofilesection={setProfileSection}
              />
            ) : (
              <FavoriteProvider
                setsubtab={setType}
                subtab={type}
                setprofilesection={setProfileSection}
              />
            )}
          </div>
          <div
            className="side_drop_collapse_box_content"
            style={
              profileSection == "Job_history"
                ? { display: "block" }
                : { display: "none" }
            }
            onClick={(e) => {
              localStorage.removeItem("side");
              setProfileSection("");
            }}
          >
            <span
              onClick={(e) =>
                setProfileSection(
                  profileSection != "Job_history" ? "Job_history" : ""
                )
              }
            ></span>
            {localStorage.getItem("user_type") == "parents" ? (
              <JobHistoryParents setsubtab={setType} subtab={type} />
            ) : (
              <JobHistory setsubtab={setType} subtab={type} />
            )}
          </div>
          <div
            className="side_drop_collapse_box_content"
            style={
              profileSection == "message"
                ? { display: "block" }
                : { display: "none" }
            }
            onClick={(e) => {
              localStorage.removeItem("side");
              setProfileSection("");
            }}
          >
            <span
              onClick={(e) => {
                localStorage.removeItem("side");
                setProfileSection("");
              }}
            ></span>
            <MessageTab setsubtab={setType} subtab={type} />
          </div>
          <ul>
            <li
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setProfileSection(
                  profileSection != "Job_history" ? "Job_history" : ""
                );
              }}
            >
              <img
                src={window.location.origin + "/images/jobnhistory.svg"}
                alt=""
              />
              <span>{t("Jobs & Doc")}</span>
            </li>
            <li
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setProfileSection(profileSection != "fav" ? "fav" : "");
              }}
            >
              <SquareServiceIcon width={"20"} height={"22"} />
              <span> {t("Recent visit")}</span>
            </li>
            <li
              onClick={(e) =>
                setProfileSection(profileSection != "message" ? "message" : "")
              }
            >
              <img
                src={window.location.origin + "/images/message.svg"}
                alt=""
              />
              <span>{t("text-messages")}</span>
            </li>
            {localStorage.getItem("user_type") == "parents" ? (
              <li
                onClick={(e) => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setType("all-profile");
                  navigate("/search-providers/all-profile");
                }}
              >
                <img src={window.location.origin + "/images/fav.svg"} alt="" />
                <span>{t("Favorites")}</span>
              </li>
            ) : (
              <li
                onClick={(e) => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setProfileSection(
                    profileSection != "Favorites" ? "Favorites" : ""
                  );
                }}
              >
                <img src={window.location.origin + "/images/fav.svg"} alt="" />
                <span>{t("Favorites")}</span>
              </li>
            )}
            <li
              onClick={(e) => {
                navigate(
                  localStorage.getItem("user_type") == "parents"
                    ? "/search-parents/Reviews"
                    : "/search-providers/Reviews"
                );
                window.scrollTo({ top: 0, behavior: "smooth" });
                setType("Reviews");
                setProfileSection("");
              }}
              className={
                type == "Reviews"
                  ? "active side_drop_collapse_none"
                  : "side_drop_collapse_none"
              }
            >
              <img
                src={window.location.origin + "/images/reviewi.svg"}
                alt=""
              />
              <span>{t("text-reviews")}</span>
            </li>
            <li
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setProfileSection(
                  profileSection != "notifications" ? "notifications" : ""
                );
              }}
            >
              <span className="count">
                <NotificationCount />
              </span>
              <img
                src={window.location.origin + "/images/notification.svg"}
                alt=""
              />
              <span>{t("Notifications")}</span>
            </li>
          </ul>
        </div>
      </div>

      {showModal && (
        <ChangePasswordModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleChangePassword={handleChangePassword}
          handleChangeVerify={handleChangeVerify}
          validateLoginFields={validateLoginFields}
          isVerified={isVerified}
        />
      )}
      {isShowSuccessModal && (
        <SuccessModal
          isShowSuccessModal={isShowSuccessModal}
          setIsShowSuccess={setIsShowSuccess}
        />
      )}

      <EditCardModal
        dataCountry={dataCountry}
        editCountry={editCountry}
        setEditCountry={setEditCountry}
        setDataCountry={setDataCountry}
      />

      {isNewCardModal && (
        <NewCardModal
          isNewCardModal={isNewCardModal}
          editCountry={editCountry}
          dataCountry={dataCountry}
          setIsNewCardModal={setIsNewCardModal}
          setEditCountry={setEditCountry}
          setDataCountry={setDataCountry}
        />
      )}

      {isMembership && (
        <MembershipModal
          setIsMembership={setIsMembership}
          isMembership={isMembership}
        />
      )}

      {isDisableAccountModal && (
        <DisableAccountModal
          handleDisableAccount={handleDisableAccount}
          setIsDisableAccountModal={setIsDisableAccountModal}
          isDisableAccountModal={isDisableAccountModal}
        />
      )}

      {isWarningModal && (
        <WarningModal
          isWarningModal={isWarningModal}
          setIsWarningModal={setIsWarningModal}
          setIsAutoDeleteModal={setIsAutoDeleteModal}
        />
      )}

      {isSurvey && (
        <SurveyModal
          isSurvey={isSurvey}
          setSurveyData={setSurveyData}
          surveyData={surveyData}
          handleEmailSurvey={handleEmailSurvey}
          handleSaveSurvey={handleSaveSurvey}
        />
      )}

      {isAutoDeleteModal && (
        <AutoDeleteModal
          handleDeleteAccount={handleDeleteAccount}
          setIsAutoDeleteModal={setIsAutoDeleteModal}
          isAutoDeleteModal={isAutoDeleteModal}
        />
      )}

      {isApologyModal && (
        <Modal
          show={isApologyModal}
          className="sorryto"
          onHide={(e) => window.location.replace("/")}
        >
          <Modal.Body>
            <div className="promocode_content cancelmembership ">
              <Link to="/" onClick={(e) => setIsApologyModal(false)}>
                +
              </Link>
              <h2>
                <img src="/images/return.png" />
                <br />
                {t("We are sorry to see you go!")}
              </h2>
              <p>
                {t("You can ")}
                <Link to={"/signup"}>{t("come back")}</Link> {t("anytime")}.
              </p>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {isSignoutModal && (
        <SignoutModal
          isSignoutModal={isSignoutModal}
          setIsSignoutModal={setIsSignoutModal}
        />
      )}

      <Modal show={showLoginModal} onHide={(e) => setShowLoginModal(false)}>
        <Modal.Body>
          <div className="promocode_content login_first">
            <Link to="" onClick={(e) => setShowLoginModal(false)}>
              +
            </Link>
            <h2>
              {t("Complete your profile and find the perfect candidate<")}
            </h2>
            <img src="/images/landing.png" />
            <p>{t("answer-question-help")}</p>
            <button>
              <Link to={"/complete-parents-profile"}>
                {t("text-complete-profile")}
              </Link>
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showGeneralProviderModal}
        onHide={(e) => setShowGeneralProviderModal(false)}
      >
        <Modal.Body>
          <div className="promocode_content login_first">
            <Link to="" onClick={(e) => setShowGeneralProviderModal(false)}>
              +
            </Link>
            <h2>{t("complete-profile-perfect-job")}</h2>
            <img src="/images/landing.png" />
            <p>{t("answer-question-find-job")}</p>
            <button>
              <Link to={"/complete-provider-profile"}>
                {t("text-complete-profile")}
              </Link>
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {isPhotoUploadModal && (
        <Modal
          show={isPhotoUploadModal}
          onHide={(e) => setIsPhotoUploadModal(false)}
        >
          <Modal.Body>
            <div className="promocode_content cancelmembership uploadphoto">
              <div className="">
                <p>
                  <strong>{t("Upload profile photos")}</strong>
                </p>
                <br />
                <ul>
                  <li>
                    {photo != "" ? (
                      <>
                        <img src={image} alt="preview image" />
                        <button onClick={(e) => setPhoto("")}></button>
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            api + "/assets/images/users/" + profile.file_path
                          }
                          alt="preview image"
                        />
                      </>
                    )}
                    <input
                      type={"file"}
                      onChange={onImageChange}
                      accept="image/*"
                    />
                  </li>
                  <li>
                    <AddIcon />
                    <button>
                      <DumpIcon />
                    </button>
                  </li>
                </ul>
                <ol>
                  <li>
                    <div className="select_photoprofile">
                      <input
                        type="radio"
                        id="photo"
                        name="photo"
                        checked="true"
                      />
                      <span>{t("Set as profile photo")}</span>
                    </div>
                  </li>
                  <li>
                    <div className="select_photoprofile">
                      <input type="radio" id="photo" name="photo" />
                      <span>{t("Set as profile photo")}</span>
                    </div>
                  </li>
                </ol>
                <br />
                <p className="giveus">{t("upload-files")}</p>

                <div className="button text-center">
                  <div className="pull-right">
                    <button
                      className="btn"
                      onClick={(e) => setIsPhotoUploadModal()}
                    >
                      {t("text-close-up")}
                    </button>
                  </div>
                  <div className="pull-right">
                    <button
                      className="btn confirm"
                      onClick={(e) => handleProfileUpdate()}
                    >
                      {t("Upload photo")}
                    </button>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <Footer />
    </>
  );
}
