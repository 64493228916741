import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { useSearchDetailsStore } from "../../store/useSearchDetailsStore";
import { useCategoryStore } from "../../store/useCategoryStore";
import { useAdditionalDetailsStore } from "../../store/useAdditionalDetailsStore";

import { ParaprofessionalIcon } from "../../assets/image/ParaprofessionalIcon";
import { TeacherIcon } from "../../assets/image/TeacherIcon";
import { TutorIcon } from "../../assets/image/TutorIcon";
import { ArrowUpIcon } from "../../assets/image/ArrowUpIcon";
import { MainDandruffIcon } from "../../assets/image/MainDandruffIcon";
import { NannyIcon } from "../../assets/image/NannyIcon";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND } from "../../utils/variables";

export const ProviderModal = ({
  handleCloseProviderModal,
  showProviderModal,
  handleProviderData,
  handleElementById,
  handleSelectCategory,
  handleSelectYears,
  setType,
}) => {
  const { t } = useTranslation();

  const { search, setSearch, usd, setUsd, profile } = useSearchDetailsStore();

  const { childrenAge } = useAdditionalDetailsStore();

  const {
    selectCategory,
    setSelectCategory,
    jobCategory,
    setJobCategory,
    isCheck,
    category,
  } = useCategoryStore();

  return (
    <Modal show={showProviderModal} onHide={handleCloseProviderModal}>
      <Modal.Body>
        <div className=" advance_search_popup">
          <Link to="" onClick={handleCloseProviderModal}>
            +
          </Link>

          <div className="" id="filteron">
            <div className="container">
              <h2>{t("Advanced Search")}</h2>
              <div
                className="filter_box"
                onKeyPress={(e) => {
                  if (
                    e.key == "Enter" &&
                    localStorage.getItem("token") &&
                    localStorage.getItem("id")
                  ) {
                    localStorage.removeItem("searchdataprovider");
                    handleProviderData();
                  }
                }}
              >
                <div className="filter_search">
                  <div className="filter_category Profile_complete ">
                    <div className="filter_category_select detail work-experience">
                      <div className="job_performance">
                        <div className="form_group   full">
                          <div className="customselect inp">
                            <input
                              id="my-fil-cat"
                              className="keyword"
                              type="text"
                              placeholder={t("select")}
                              value={category.map((e) => {
                                return e.name;
                              })}
                            />
                            <div
                              className="overflow"
                              id="over5"
                              onClick={(e) =>
                                handleElementById("cate8", "over5")
                              }
                            ></div>
                            <div className="option" id="cate8">
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) => {
                                    handleSelectCategory("Nanny");
                                    if (a.target.checked) {
                                      setSelectCategory({
                                        ...selectCategory,
                                        data1: "Nanny",
                                      });
                                    } else {
                                      setSelectCategory({
                                        ...selectCategory,
                                        data1: "",
                                      });
                                    }
                                  }}
                                />
                                <h3>
                                  <NannyIcon />
                                  {t("text-nanny")}
                                </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) => {
                                    handleSelectCategory(
                                      "Special education teacher"
                                    );
                                    if (a.target.checked) {
                                      setSelectCategory({
                                        ...selectCategory,
                                        data2: "Special education teacher",
                                      });
                                    } else {
                                      setSelectCategory({
                                        ...selectCategory,
                                        data2: "",
                                      });
                                    }
                                  }}
                                />
                                <h3>
                                  <TeacherIcon />
                                  {t("spec-education-teacher")}
                                </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) => {
                                    handleSelectCategory(
                                      "Special education paraprofessional"
                                    );
                                    if (a.target.checked) {
                                      setSelectCategory({
                                        ...selectCategory,
                                        data3:
                                          "Special education paraprofessional",
                                      });
                                    } else {
                                      setSelectCategory({
                                        ...selectCategory,
                                        data3: "",
                                      });
                                    }
                                  }}
                                />
                                <h3>
                                  <ParaprofessionalIcon />
                                  {t("Special education paraprofessional")}
                                </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) => {
                                    handleSelectCategory("Tutor");
                                    if (a.target.checked) {
                                      setSelectCategory({
                                        ...selectCategory,
                                        data4: "Tutor",
                                      });
                                    } else {
                                      setSelectCategory({
                                        ...selectCategory,
                                        data4: "",
                                      });
                                    }
                                  }}
                                />
                                <h3>
                                  <TutorIcon />
                                  {t("text-tutor")}
                                </h3>
                                <span></span>
                              </p>
                            </div>
                            <span
                              onClick={(e) =>
                                handleElementById("cate8", "over5")
                              }
                            >
                              <ArrowUpIcon color={"#7D2B8B"} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="city keyword">
                    <input
                      type="text"
                      placeholder={t("city")}
                      id="my-fil-city"
                      onChange={(e) =>
                        setSearch({ ...search, city: e.target.value })
                      }
                    />
                  </div>
                  <div className="zip code">
                    <input
                      type="text"
                      placeholder={t("zip")}
                      id="my-fil-zip"
                      onChange={(e) =>
                        setSearch({ ...search, zip: e.target.value })
                      }
                    />
                  </div>
                  <div className="distance">
                    <div className="distance_input">
                      <select
                        name=""
                        id="my-fil-dis"
                        onChange={(e) =>
                          setSearch({
                            ...search,
                            distance: e.target.value,
                          })
                        }
                      >
                        <option value="" selected>
                          {t("dist")}
                        </option>
                        <option value={5}>
                          5
                          {profile && profile.country == "Serbia"
                            ? "Km"
                            : "Miles"}
                        </option>
                        <option value={10}>
                          10
                          {profile && profile.country == "Serbia"
                            ? "Km"
                            : "Miles"}
                        </option>
                        <option value={15}>
                          15
                          {profile && profile.country == "Serbia"
                            ? "Km"
                            : "Miles"}
                        </option>
                        <option value={20}>
                          20
                          {profile && profile.country == "Serbia"
                            ? "Km"
                            : "Miles"}
                        </option>
                        <option value={25}>
                          25
                          {profile && profile.country == "Serbia"
                            ? "Km"
                            : "Miles"}
                        </option>
                        <option value={30}>
                          30
                          {profile && profile.country == "Serbia"
                            ? "Km"
                            : "Miles"}
                        </option>
                        <option value={35}>
                          35
                          {profile && profile.country == "Serbia"
                            ? "Km"
                            : "Miles"}
                        </option>
                        <option value={40}>
                          40
                          {profile && profile.country == "Serbia"
                            ? "Km"
                            : "Miles"}
                        </option>
                        <option value={45}>
                          45
                          {profile && profile.country == "Serbia"
                            ? "Km"
                            : "Miles"}
                        </option>
                        <option value={50}>
                          50
                          {profile && profile.country == "Serbia"
                            ? "Km"
                            : "Miles"}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="advance_search_filter">
                  <div
                    className={
                      "advance_search_filter_input Profile_complete " +
                      (!isCheck ? "op" : "")
                    }
                  >
                    <div
                      className="filters_search detail work-experience"
                      id="filterdata"
                    >
                      <div className="form_group full border qualification selectbt">
                        <label>{t("text-frequency")}</label>
                        <div className="checkbox create">
                          <ul>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setSearch({
                                      ...search,
                                      tutorintrestedin: "Full time",
                                    });
                                  } else {
                                    setSearch({
                                      ...search,
                                      tutorintrestedin: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("full-time")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setSearch({
                                      ...search,
                                      tutorintrestedin1: "Part time",
                                    });
                                  } else {
                                    setSearch({
                                      ...search,
                                      tutorintrestedin1: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("part-time")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name=""
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setSearch({
                                      ...search,
                                      tutorintrestedin2: "Occasionally",
                                    });
                                  } else {
                                    setSearch({
                                      ...search,
                                      tutorintrestedin2: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("occasionally")}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form_group full border">
                        <label>
                          {t("Hourly rate")}
                          {profile && profile.country == "Serbia"
                            ? "(RSD)"
                            : "(USD)"}
                        </label>
                        {profile && profile.country == "Serbia" ? (
                          <div className="wrapper">
                            <div className="container_slide">
                              <div className="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      usd.min >= value ||
                                      (usd.max < value && usd.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div className="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      usd.min >= value ||
                                      (usd.max < value && usd.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={usd.min / 10}
                                onChange={(e) => {
                                  if (
                                    (usd.max > 0 ? usd.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setUsd({
                                      ...usd,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={usd.max == 0 ? 50 / 10 : usd.max / 10}
                                onChange={(e) => {
                                  if (
                                    usd.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setUsd({
                                      ...usd,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="slider two">
                              <ul>
                                <li>0</li>
                                <li>1000</li>
                                <li>2000</li>
                                <li>3000</li>
                                <li>4000</li>
                                <li>6000+</li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div className="wrapper">
                            <div className="container_slide">
                              <div className="slider-track">
                                <ul>
                                {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      usd.min >= value ||
                                      (usd.max < value && usd.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <div className="bggray_slider">
                                <ul>
                                {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      usd.min >= value ||
                                      (usd.max < value && usd.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                                </ul>
                              </div>
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-1"
                                value={usd.min / 10}
                                onChange={(e) => {
                                  if (
                                    (usd.max > 0 ? usd.max : 60) >
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setUsd({
                                      ...usd,
                                      min:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                              <input
                                type="range"
                                min="0"
                                max="5"
                                id="slider-2"
                                value={usd.max == 0 ? 50 / 10 : usd.max / 10}
                                onChange={(e) => {
                                  if (
                                    usd.min <
                                    (e.target.value == 5
                                      ? 60
                                      : e.target.value * 10)
                                  ) {
                                    setUsd({
                                      ...usd,
                                      max:
                                        e.target.value == 5
                                          ? 60
                                          : e.target.value * 10,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="slider two">
                              <ul>
                                <li>0</li>
                                <li>10</li>
                                <li>20</li>
                                <li>30</li>
                                <li>40</li>
                                <li>60+</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <span className="price">
                          {profile && profile.country == "Serbia"
                            ? usd.min * 100 + " - " + usd.max * 100
                            : usd.min +
                              " - " +
                              (usd.max > 0
                                ? usd.max == 60
                                  ? usd.max + "+"
                                  : usd.max
                                : usd.max == 60
                                ? 60 + "+"
                                : 60 + "+")}
                        </span>
                        {/* <div className='errorfield'>{error.message}</div> */}
                      </div>
                      <div className="form_group full border">
                        <label>{t("Number of children")}</label>
                        <div className="children_number">
                          <ul>
                            <li
                              onClick={(e) => {
                                if (jobCategory.data1 == "") {
                                  setJobCategory({
                                    ...jobCategory,
                                    data1: 1,
                                  });
                                } else {
                                  setJobCategory({
                                    ...jobCategory,
                                    data1: "",
                                  });
                                }
                              }}
                              className={jobCategory.data1 == 1 ? "active" : ""}
                            >
                              1
                            </li>
                            <li
                              onClick={(e) => {
                                if (jobCategory.data2 == "") {
                                  setJobCategory({
                                    ...jobCategory,
                                    data2: 2,
                                  });
                                } else {
                                  setJobCategory({
                                    ...jobCategory,
                                    data2: "",
                                  });
                                }
                              }}
                              className={jobCategory.data2 == 2 ? "active" : ""}
                            >
                              2
                            </li>
                            <li
                              onClick={(e) => {
                                if (jobCategory.data3 == "") {
                                  setJobCategory({
                                    ...jobCategory,
                                    data3: "twins",
                                  });
                                } else {
                                  setJobCategory({
                                    ...jobCategory,
                                    data3: "",
                                  });
                                }
                              }}
                              className={
                                jobCategory.data3 == "twins" ? "active" : ""
                              }
                            >
                              {t("text-twins")}
                            </li>
                            <li
                              onClick={(e) => {
                                if (jobCategory.data4 == "") {
                                  setJobCategory({
                                    ...jobCategory,
                                    data4: "3",
                                  });
                                } else {
                                  setJobCategory({
                                    ...jobCategory,
                                    data4: "",
                                  });
                                }
                              }}
                              className={jobCategory.data4 == 3 ? "active" : ""}
                            >
                              3+
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job_performance">
                        <div className="form_group   full border">
                          <label>{t("Child’s age1")}</label>

                          <div className="customselect inp">
                            <input
                              className="keyword"
                              type="text"
                              placeholder={t("choose-list")}
                              value={childrenAge.map((e) => {
                                return e.name;
                              })}
                            />
                            <div
                              className="overflow"
                              id="over9"
                              onClick={(e) =>
                                handleElementById("cate9", "over9")
                              }
                            ></div>
                            <div className="option" id="cate9">
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) =>
                                    handleSelectYears(t("years-0-1"))
                                  }
                                />
                                <h3>{t("years-0-1")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) =>
                                    handleSelectYears(t("years-4-7"))
                                  }
                                />
                                <h3>{t("years-4-7")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) =>
                                    handleSelectYears(t("years-8-10"))
                                  }
                                />
                                <h3>{t("years-8-10")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) =>
                                    handleSelectYears(t("years-11-15"))
                                  }
                                />
                                <h3>{t("years-11-15")} </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) =>
                                    handleSelectYears(t("years-16"))
                                  }
                                />
                                <h3>{t("years-16")} </h3>
                                <span></span>
                              </p>
                            </div>
                            <span
                              onClick={(e) =>
                                handleElementById("cate9", "over9")
                              }
                            >
                              <ArrowUpIcon />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form_group full border qualification">
                        <label>{t("start-date")}</label>
                        <div className="checkbox create">
                          <ul>
                            <li>
                              <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                  setSearch({
                                    ...search,
                                    withinweek: e.target.checked,
                                  });
                                }}
                              />
                              <span> {t("Within a week")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                  setSearch({
                                    ...search,
                                    withinmonth: e.target.checked,
                                  });
                                }}
                              />
                              <span> {t("Within a month")}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form_group full qualification selectbt">
                        <label>{t("Other options")}</label>
                        <div className="checkbox create">
                          <ul>
                            <li>
                              <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                  setSearch({
                                    ...search,
                                    carorlicence: e.target.checked,
                                  });
                                }}
                              />
                              <span> {t("Without transportation")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                  setSearch({
                                    ...search,
                                    jobposted: e.target.checked,
                                  });
                                }}
                              />
                              <span> {t("Jobs posted within a week")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setSearch({
                                      ...search,
                                      workingabroad: "Yes",
                                    });
                                  } else {
                                    setSearch({
                                      ...search,
                                      workingabroad: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("Work abroad")}</span>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                name="b"
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setSearch({
                                      ...search,
                                      tutorintrestedinschool: "Yes",
                                    });
                                  } else {
                                    setSearch({
                                      ...search,
                                      tutorintrestedinschool: "",
                                    });
                                  }
                                }}
                              />
                              <span> {t("School jobs")}</span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <button
                        onClick={(e) => {
                          setType("");
                          localStorage.removeItem("searchdataprovider");
                          handleProviderData();
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          handleCloseProviderModal();
                        }}
                      >
                        <MainDandruffIcon />
                        {t("Refine result")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
