import { DEFAULT_API } from "../utils/variables";

export const fetchRegisterUser = async (
    formLogin,
) => {
  const formdata = new FormData();
  formdata.append("countrycode", formLogin.contactCode?.code);
  formdata.append("username", formLogin.username.toLowerCase());
  formdata.append("email", formLogin.email.toLowerCase());
  formdata.append("password", formLogin.password);
  formdata.append("c_password", formLogin.c_password);

  formdata.append("service_type", formLogin.service_type?.join(',') || '');

  formdata.append("first_name", formLogin.first_name);
  formdata.append("last_name", formLogin.last_name);
  formdata.append("dob", formLogin.dob);
  formdata.append("phone", formLogin.phone);
  formdata.append("address", formLogin.address);
  formdata.append("city", formLogin.city);
  formdata.append("zip", formLogin.zip);
  formdata.append("country", formLogin.country);
  formdata.append("hearAboutUs", formLogin.hearAboutUs);
  formdata.append("user_type", formLogin.user_type);
  formdata.append(
    "referralId",
    "eyJpdiI6IlNaODJ2dG1hOEsrbVdjdDhHR1BhY1E9PSIsInZhbHVlIjoicE1yVVpyeWNNZ1VxYkY5cDJJNjJZUT09IiwibWFjIjoiYTc5ODdjZDM1NTc2N2MxMzY2NjEzZTRkMDlkZTZmM2Y4N2M2NGE3YmJhY2Q5YjA0YmFhNDRhODE0ZWM1OThjMSJ9"
  );

  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${DEFAULT_API}/api/register`, requestOptions);
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error registering user:", error);
    throw error;
  }
};
