import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";

import { REQUIRED_FIELDS } from "../../utils/variables";
import { validateFieldAfterLogin } from "../../utils/helperValidations";
import { useFormStore } from "../../store/useFormStore";
import { fetchRegisterUser } from "../../api/registerApi";
import { ProviderSignupForm } from "./ProviderSignupForm";

export function ProviderSignupPage() {
  const { t } = useTranslation();

  const [category, setCategory] = useState("provider");
  const [describe, setDescribe] = useState({});
  console.log(describe, "DESCIBE");
  const [step, setStep] = useState("stap2");
  const [showModal, setShowModal] = useState(false);
  const [isSelect, setIsSelect] = useState("");

  const [latLong, setLatLong] = useState({});
  const [contactCode, setContactCode] = useState({
    code: "",
    flag: "",
  });
  const [code, setCode] = useState(true);

  const {
    formLogin,
    setFormLogin,
    setErrorField,
    resetFormLogin,
    resetErrorField,
  } = useFormStore();

  useEffect(() => {
    return () => {
      resetFormLogin();
      resetErrorField();
    };
  }, []);

  const today = new Date();

  const handleCodeSelect = () => {
    if (code) {
      setCode(false);
    } else {
      setCode(true);
    }
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setFormLogin({ ...formLogin, dob: "" });
    setShowModal(true);
  };

  const handleSignup = async () => {
    const isFormValid = [
      formLogin.username,
      contactCode.code,
      formLogin.email,
      formLogin.password,
      formLogin.c_password,
      formLogin.first_name,
      formLogin.last_name,
      formLogin.dob,
      formLogin.phone,
      formLogin.address,
      formLogin.city,
      formLogin.zip,
      formLogin.country,
      formLogin.hearAboutUs,
    ].every((field) => field !== "");

    if (isFormValid) {
      try {
        const result = await fetchRegisterUser(
            {
              ...formLogin,
              user_type: category,
              service_type: Object.keys(describe),
            },
            contactCode,
            latLong
        );

        if (result.errors) {
          const { email, username } = result.errors.error;
          setErrorField({
            email: email ? t("The email has already been taken.") : "",
            username: username ? "The username has already been taken." : "",
            first_name: "",
          });
        }

        if (result.success) {
          toast.success(result.message);
          setTimeout(() => {
            window.location.href =
              category === "parents"
                ? "/parent_thankyou"
                : "/providers_thankyou";
          }, 2010);
        }
      } catch (error) {
        console.error("Error registering user:", error);

      if (error.toString().includes("The email has already been taken.")) {
        setErrorField((prevErrors) => ({
          ...prevErrors,
          email: t("The email has already been taken."),
        }));
      }

      if (error.toString().includes("The username has already been taken.")) {
        setErrorField((prevErrors) => ({
          ...prevErrors,
          username: t("The username has already been taken."),
        }));
      }
      }
    } else {
      REQUIRED_FIELDS.forEach((el) => validateAfterLoginField(el));
    }
  };

  const validateAfterLoginField = (name) => {
    const error = validateFieldAfterLogin(name, formLogin, contactCode, t);

    setErrorField((prevErrorField) => ({
      ...prevErrorField,
      [name]: error,
    }));
  };

  const handleDescribeSelect = (a, b) => {
    if (Object.keys(describe).length < 2) {
      setDescribe({ ...describe, [a]: b });
    } else {
      delete describe[a];
      setDescribe({ ...describe });
    }
    console.log(describe);
  };

  const handleRedirect = () => {
    window.location.href = "/signup";
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="signup_section">
            <div className="header_sign">
              <ul>
                <li>
                  <Link to="/">
                    <img src="./images/left_arrow.svg" />
                    <span>{t("Home")}</span>
                  </Link>
                </li>
                <li className="login">
                  <span>{t("Already a member?")}</span>
                  <Link to="/login">{t("log-in")} </Link>
                </li>
              </ul>
            </div>

            <div
              className={
                "describe more_about " + (step == "stap2" ? "" : "none")
              }
            >
              <img src="/images/sign_logo.svg" />
              <h2>{t("What describes you best")}</h2>
              <p>
                <span>{t("I am")} </span> (
                {t("Please select up to two professions")}) <br />
                {t("signup-change-choice")}
              </p>
              <div className="process_guid ">
                <ul>
                  <li
                    className={
                      describe && describe.tab1 == t("text-nanny")
                        ? "active"
                        : "" + isSelect == ""
                          ? ""
                          : "bordererror"
                    }
                    onClick={(e) => {
                      handleDescribeSelect("tab1", t("text-nanny"));
                      setIsSelect("");
                    }}
                  >
                    <Link to="">
                      <img
                        src={
                          describe && describe.tab1 == t("text-nanny")
                            ? "./images/nanny_fill.svg"
                            : "./images/nanny.svg"
                        }
                      />
                      <span>{t("text-nanny")}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      describe && describe.tab2 == t("education-teacher")
                        ? "active"
                        : "" + isSelect == ""
                          ? ""
                          : "bordererror"
                    }
                    onClick={(e) => {
                      handleDescribeSelect("tab2", t("education-teacher"));
                      setIsSelect("");
                    }}
                  >
                    <Link to="">
                      <img
                        src={
                          describe && describe.tab2 == t("education-teacher")
                            ? "./images/teacher_fill.svg"
                            : "./images/teacher.svg"
                        }
                      />
                      <span>{t("education-teacher")}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      describe &&
                      describe.tab3 == t("education-paraprofessional")
                        ? "active"
                        : "" + isSelect == ""
                          ? ""
                          : "bordererror"
                    }
                    onClick={(e) => {
                      handleDescribeSelect(
                        "tab3",
                        t("education-paraprofessional")
                      );
                      setIsSelect("");
                    }}
                  >
                    <Link to="">
                      <img
                        src={
                          describe &&
                          describe.tab3 == t("education-paraprofessional")
                            ? "./images/education_fill.svg"
                            : "./images/education.svg"
                        }
                      />
                      <span>{t("education-paraprofessional")}</span>
                    </Link>
                  </li>
                  <li
                    className={
                      describe && describe.tab4 == t("text-tutor")
                        ? "active"
                        : "" + isSelect == ""
                          ? ""
                          : "bordererror"
                    }
                    onClick={(e) => {
                      handleDescribeSelect("tab4", t("text-tutor"));
                      setIsSelect("");
                    }}
                  >
                    <Link to="">
                      <img
                        src={
                          describe && describe.tab4 == t("text-tutor")
                            ? "./images/tutor_fill.svg"
                            : "./images/tutor.svg"
                        }
                      />
                      <span>{t("text-tutor")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <button className="back_sign" onClick={handleRedirect}>
                {t("text-back")}
              </button>
              <button
                onClick={(e) => {
                  Object.keys(describe).length >= 1
                    ? setStep("stap3")
                    : setIsSelect("Please select your needs.");
                }}
              >
                {t("text-next")}
              </button>
            </div>

            <div
              className={
                "describe more_about form_signup " +
                (step == "stap3" ? "" : "none")
              }
            >
              <img src="/images/sign_logo.svg" />
              <h2>{t("Tell us more about yourself")}</h2>

              <ProviderSignupForm
                today={today}
                handleShowModal={handleShowModal}
                handleCodeSelect={handleCodeSelect}
                contactCode={contactCode}
                code={code}
                setContactCode={setContactCode}
              />

              <p>
                {t("By clicking on “Sign up” you agree to our")}
                <Link to="/terms-of-use" target={"_blank"}>
                  {t("Terms of Use")}
                </Link>
                {t("text-and")}
                <Link to="/privacy-policy" target={"_blank"}>
                  {t("Privacy Policy")}
                </Link>
                .
              </p>
              <button className="back_sign" onClick={(e) => setStep("stap2")}>
                {t("text-back")}
              </button>
              <button
                onClick={() => {
                  handleSignup();
                }}
              >
                <Link to="#">{t("sign-up")}</Link>
              </button>
            </div>
            <div
              className={
                "signupdetail " +
                (step == "stap2" || step == "stap2" || step == "stap3"
                  ? ""
                  : "none")
              }
            >
              <h2>
                {t("sign-up")} <br /> {t("for FREE!")}
              </h2>
              <ul>
                <li>
                  <img src="./images/thanks_right.svg" />
                  <span>{t("Create your profile.")}</span>
                </li>
                <li>
                  <img src="./images/thanks_right.svg" />
                  <span>{t("Browse and apply for child services jobs.")}</span>
                </li>
                <li>
                  <img src="./images/thanks_right.svg" />
                  <span>{t("Connect with schools and families.")}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="promocode_content younger">
              <Link to="" onClick={handleCloseModal}>
                +
              </Link>
              <h5>{t("Thank you!")}</h5>
              <p>{t("post-about-persons-younder")}</p>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
